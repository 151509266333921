import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ToastService } from "../../../Core/Toast/toast.service";
import { UserService } from "../../../Core/Services/User/user.service";

@Component({
  selector: "app-support",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [CommonModule, FormsModule, TranslateModule],
  templateUrl: "./support.component.html",
  styleUrl: "./support.component.scss",
})
export class SupportComponent implements OnInit, OnDestroy {
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private toasterService: ToastService,
    private userService: UserService
  ) {}
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }
  types: { id: number; name: string; type: string }[] = [];
  subTypes: { id: number; name: string; type: string }[] = [];
  selected_type?: { id: number; name: string; type: string };
  selected_sub_type?: { id: number; name: string; type: string };
  message: string = "";
  onLangChange?: Subscription;

  messageSent: boolean = false;

  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCall();
    });
    this.apiCall();
  }

  apiCall() {
    this.apiService.getSupportMessageTypes().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        if (response.result) {
          this.subTypes = [];
          this.selected_type = undefined;
          this.types = response.data;
        }
      },
    });
  }

  selectChanged() {
    this.subTypes = [];
    this.selected_sub_type = undefined;
    if (this.selected_type?.type) {
      this.apiService
        .getSupportMessageSubType(this.selected_type.type)
        .subscribe({
          next: (response) => {
            if (response.result) {
              this.subTypes = response.data;
            }
          },
        });
    }
  }

  sendSupportMessage() {
    let type = this.selected_sub_type
      ? this.selected_sub_type
      : this.selected_type;
    if (type) {
      this.userService.email.subscribe({
        next: (email) => {
          this.userService.name.subscribe({
            next: (name) => {
              if (type) {
                this.apiService
                  .sendSupportMessage(type, this.message, email, name)
                  .subscribe({
                    next: (response) => {
                      if (!response.data) {
                        this.toasterService.showAlert("Something went wrong!");
                        return;
                      }
                      this.messageSent = true;
                    },
                  });
              }
            },
          });
        },
      });
    }
  }
}
