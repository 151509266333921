import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { OrderData } from "../../../../Core/models/Order";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { UserService } from "../../../../Core/Services/User/user.service";
import { Subscription, firstValueFrom, timer } from "rxjs";
import { Card } from "../../../../Core/models/Card";
import { FormsModule } from "@angular/forms";
import RevolutCheckout from "@revolut/checkout";
import { environment } from "../../../../../environments/environment";
import { CurrencyService } from "../../../../Core/Services/Currency/currency.service";
import { SuccessModalComponent } from "../../../cart/success-modal/success-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogService } from "../../../../Core/Services/ConfirmDialog/confirm-dialog.service";

@Component({
  selector: "app-order-data",
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: "./order-data.component.html",
  styleUrl: "./order-data.component.scss",
})
export class OrderDataComponent implements OnInit, OnDestroy {
  @Input() orderData?: OrderData;
  @Input() cards: Card[] = [];
  @Input() displayArchive: boolean = false;
  paymentToken?: string;

  showArchive: boolean = false;
  showFailed: boolean = false;

  paymentSts:
    | undefined
    | "need_check"
    | "not_paid"
    | "checking"
    | "paid"
    | "deleted"
    | "failed" = undefined;
  orderSts:
    | undefined
    | "pending"
    | "processing"
    | "completed"
    | "failed"
    | "deleted"
    | "archive" = undefined;

  paymentProcessing: boolean = false;
  time = timer(5000);
  selectedCard: Card | null = null;
  paymentSub: Subscription | null = null;
  orderSub: Subscription | null = null;
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    public currencyService: CurrencyService,
    private modalService: NgbModal,
    private confirmDialogService: ConfirmDialogService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.orderData?.paymentsts == "C") this.paymentSts = "paid";
    if (this.orderData?.sts == "F") {
      this.orderSts = "failed";
      this.paymentSts = "failed";
    } else if (this.orderData?.sts == "L") {
      this.orderSts = "completed";
    } else if (this.orderData?.sts == "A") {
      this.orderSts = "archive";
    } else if (this.orderData?.sts == "D") {
      this.orderSts = "deleted";
      this.paymentSts = "deleted";
    } else if (this.paymentSts != "paid") {
      this.orderSts = "pending";
    } else {
      this.orderSts = "processing";
      this.getOrderStatus();
    }

    this.orderData?.orderdetail.forEach((element) => {
      let vps = this.orderData?.vps.find(
        (vps) => vps.orderdetailid == element.id
      );
      if (vps) {
        element.sname += " (" + vps.name + ")";
      }
    });
    await this.checkPaymentStatus();
    if (this.paymentSts == "need_check") {
      this.getPaymentStatus();
    }
  }

  async checkPaymentStatus() {
    if (!this.orderData) return;
    if (this.paymentSts == "paid") return;
    if (this.paymentSts == undefined) {
      if (this.orderData) {
        let res = await firstValueFrom(
          this.apiService.getOrder(this.orderData?.id)
        );
        if (res.result && res.data.length > 0) {
          if (res.data[0].paymentsts == "C") {
            this.paymentSts = "paid";
            if (this.orderSts == "pending") {
              this.orderSts = "processing";
              this.getOrderStatus();
            }
          } else {
            this.paymentSts = "need_check";
          }
        }
      }
    }
  }

  getOrderStatus() {
    this.orderSub = this.time.subscribe((response) => {
      if (this.orderData?.id) {
        this.apiService.getOrder(this.orderData?.id).subscribe({
          next: (response) => {
            if (response.result && response.data.length > 0) {
              if (response.data[0].sts == "F") {
                this.orderSts = "failed";
              } else if (response.data[0].sts == "L") {
                this.orderSts = "completed";
              } else if (response.data[0].sts == "A") {
                this.orderSts = "archive";
              } else {
                this.getOrderStatus();
              }
            }
          },
        });
      }
    });
  }

  getPaymentStatus() {
    if (this.orderData) {
      this.apiService.checkOrderPayment(this.orderData?.id).subscribe({
        next: (response) => {
          if (response.result && response.data.length > 0) {
            if (response.data[0].state == "pending") {
              this.paymentSts = "not_paid";
              this.paymentToken = response.data[0].token;
            } else if (response.data[0].state == "completed") {
              this.paymentSts = "paid";
              this.modalService.open(SuccessModalComponent, { centered: true });
              if (this.orderSts == "pending") {
                this.orderSts = "processing";
                this.getOrderStatus();
              }
            } else {
              this.paymentSts = "checking";
              this.paymentSub = this.time.subscribe((response) => {
                this.getPaymentStatus();
              });
            }
          }
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.paymentSub?.unsubscribe();
    this.orderSub?.unsubscribe();
  }

  payOrder() {
    if (
      this.selectedCard &&
      this.userService.customerId &&
      this.orderData &&
      this.selectedCard.card_brand &&
      this.selectedCard.card_last_four &&
      this.selectedCard.type
    ) {
      this.apiService
        .payOrderWithCard(
          this.userService.customerId,
          this.orderData?.id,
          this.selectedCard.type,
          this.selectedCard.card_brand,
          this.selectedCard.card_last_four
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.getPaymentStatus();
            }
          },
        });
    } else if (this.paymentToken) {
      RevolutCheckout(
        this.paymentToken,
        environment.revolut_sabdbox ? "sandbox" : "prod"
      ).then((instance) => {
        instance.payWithPopup({
          onSuccess: () => {
            this.paymentSts = "checking";
            this.getPaymentStatus();
          },
          onCancel: () => {},
          onError: (err) => {
            console.error(err);
          },
          email: this.orderData?.customeruseremail,
          savePaymentMethodFor: "merchant",
        });
      });
    }
  }

  deleteOrder() {
    this.confirmDialogService
      .openConfirmDialog(
        "order_data.delete_title",
        "order_data.delete_message",
        "basic.yes",
        "basic.cancel",
        true
      )
      .subscribe({
        next: (response) => {
          if (response && this.orderData) {
            this.apiService.orderDelete(this.orderData.id).subscribe({
              next: (response) => {
                if (response.result) {
                  this.orderSts = "deleted";
                  this.paymentSts = "deleted";
                }
              },
            });
          }
        },
      });
  }
}
