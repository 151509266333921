<div class="config-panel">
  <div class="title">{{ title }}</div>
  <div class="param-title">{{ "vps-page.params" | translate }}</div>
  <div class="info-panel">
    <div *ngFor="let param of params" class="info">
      <span>{{ param.name }}</span
      >: {{ param.value }}
    </div>
  </div>
  <div class="extra-title">
    {{ "vps-page.extras" | translate }}
  </div>
  <div class="extra-list">
    <div class="extra" *ngFor="let extra of extras; index as i">
      <span class="extra-text me-1">{{ extra.name }}</span>
      <app-checkbox-input
        [name]="extra.items[0].id"
        [info]="extra.items[0].html"
        *ngIf="extra.type == 'chek'"
        class="extra-input"
        [cartName]="extra.name"
        (onChange)="extraValueChanged()"
      ></app-checkbox-input>
      <app-number-select
        *ngIf="extra.type == 'csuszka'"
        class="extra-input"
        [info]="extra.items[0].html"
        [min]="extra.items[0].qmin"
        [max]="extra.items[0].qmax"
        [step]="extra.items[0].qstep"
        [name]="extra.items[0].id"
        [cartName]="extra.name"
        (onChange)="extraValueChanged()"
      ></app-number-select>
      <app-string-select
        *ngIf="extra.type == 'select'"
        class="extra-input"
        [name]="'select[' + i + ']'"
        [values]="extra.items"
        [cartName]="extra.name"
        (onChange)="extraValueChanged()"
      ></app-string-select>
    </div>
  </div>
  <div class="extra-list">
    <div class="extra">
      <span class="extra-text me-1"> {{ "period.payment" | translate }}</span>
      <select
        name="priceTypeSelect"
        (change)="periodChange.emit(period)"
        [(ngModel)]="period"
        [disabled]="!!priceTypeS"
        class="form-control form-select extra-input text-center fs-6"
      >
        <ng-container *ngFor="let priceType of pricetypes">
          <option
            value="{{ priceType.id }}"
            *ngIf="avaibalePeriods && isAvailable(priceType.id)"
          >
            {{ priceType.name }}
          </option>
        </ng-container>
      </select>
    </div>
  </div>
</div>
