import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { Card } from "../../../../Core/models/Card";
import { CommonModule } from "@angular/common";
import { ConfirmDialogService } from "../../../../Core/Services/ConfirmDialog/confirm-dialog.service";
import { UserService } from "../../../../Core/Services/User/user.service";
import { Address } from "../billing-address/billing-address.component";
import RevolutCheckout from "@revolut/checkout";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-cards",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./cards.component.html",
  styleUrl: "./cards.component.scss",
})
export class CardsComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private confirmService: ConfirmDialogService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}
  cards: Card[] = [];
  addresses: Address[] = [];
  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    this.getCards();
    this.getBillingAddresses();
  }

  testAddCard() {
    if (this.userService.customerId && this.addresses[0].id) {
      this.apiService
        .createUserCard(this.userService.customerId, this.addresses[0].id)
        .subscribe({
          next: (response) => {
            if (!response.data.revolut) {
              return;
            }
            RevolutCheckout(
              response.data.revolut.token,
              environment.revolut_sabdbox ? "sandbox" : "prod"
            ).then((instance) => {
              instance.payWithPopup({
                onSuccess: () => {},
                onCancel: () => {},
                onError() {},
                email: response.data.order.customeruseremail,
                savePaymentMethodFor: "merchant",
              });
            });
          },
        });
    }
  }

  getCards() {
    this.apiService.getUserCards().subscribe({
      next: (response) => {
        this.cards = response.data;
      },
    });
  }

  getBillingAddresses() {
    this.apiService.GetCustomerAddress().subscribe({
      next: (response) => {
        if (response.result) {
          this.addresses = response.data;
        }
      },
    });
  }

  deleteCard(card: Card) {
    this.translateService
      .get([
        "confirm_dialog.delete_card.title",
        "confirm_dialog.delete_card.message",
        "confirm_dialog.delete_card.confirm",
        "confirm_dialog.delete_card.decline",
      ])
      .subscribe({
        next: (translations) => {
          this.confirmService
            .openConfirmDialog(
              translations["confirm_dialog.delete_card.title"],
              translations["confirm_dialog.delete_card.message"],
              translations["confirm_dialog.delete_card.confirm"],
              translations["confirm_dialog.delete_card.decline"]
            )
            .subscribe({
              next: (response) => {
                if (response) {
                  if (
                    card.customerid &&
                    card.type &&
                    card.card_brand &&
                    card.card_last_four
                  ) {
                    this.apiService
                      .deleteUserCard(
                        card.customerid,
                        card.type,
                        card.card_brand,
                        card.card_last_four
                      )
                      .subscribe({
                        next: (res) => {
                          this.getCards();
                        },
                      });
                  }
                }
              },
            });
        },
      });
  }
}
