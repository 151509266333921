import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UserDataComponent } from "../../../Pages/user/register/user-data/user-data.component";
import { ApiService } from "../../Services/Api/api.service";
import { UserService } from "../../Services/User/user.service";
import { ToastService } from "../../Toast/toast.service";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "CLM-try-vps",
  standalone: true,
  imports: [TranslateModule, FormsModule, UserDataComponent],
  templateUrl: "./try-vps.component.html",
  styleUrl: "./try-vps.component.scss",
})
export class TryVpsComponent {
  @ViewChild(UserDataComponent) user?: UserDataComponent;
  @Output() registered: EventEmitter<{
    username: string;
    email: string;
    password: string;
  }> = new EventEmitter<{
    username: string;
    email: string;
    password: string;
  }>();
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private toastService: ToastService
  ) {}
  check_2: boolean = false;

  try() {
    if (this.user) {
      this.registered.emit({
        username: this.user.name?.value!,
        email: this.user.email?.value!,
        password: this.user.password?.value!,
      });
    }
  }
}
