import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-blog",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./blog.component.html",
  styleUrl: "./blog.component.scss",
})
export class BlogComponent implements OnInit {
  blog_url: string = "";
  blog: any = null;
  constructor(
    private apiSerivce: ApiService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {
    route.paramMap.subscribe({
      next: (param) => {
        this.blog_url = String(param.get("url"));
      },
    });
  }

  ngOnInit(): void {
    this.apiSerivce.getBlog(this.blog_url).subscribe((response) => {
      if (response.result) {
        this.blog = response.data[0];
      }
    });
  }
}
